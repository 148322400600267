import { useLocation, useNavigate } from "@remix-run/react";
import { useEffect } from "react";
import { trackEvent, trackPageView } from "src/modules/analytics/api/analytics";

export default function Login() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageView(location.pathname);
    trackEvent({
      category: "Login",
      action: "Login",
      label: "Redirected to login",
      nonInteraction: true,
    });

    navigate("/auth/login");
  }, [location.pathname, navigate]);

  return <div>Redirecting to login...</div>;
}
